<template>
  <div>
    <div class="main-container">
      <el-row>
        <el-col :span="24">
          <div class="description">
            <h2 class="norte-title">Mint Norte</h2>
            <p>
              Your web3 identity is here. A radically fresh experience, our
              inaugural project Norte empowers you to create and mint beautiful
              art pieces that project your Ethereum address. All on-chain, by
              <a href="https://www.cryptoarte.io/about" target="_blank"
                >Seb (CryptoArte)</a
              >.
            </p>
          </div>
        </el-col>
      </el-row>
      <div class="norte">
        <div v-if="!addressStateRef">
          <div class="connect-wallet-alert-div">
            <el-alert
              class="connect-wallet-alert"
              :closable="false"
              effect="dark"
              type="warning"
              show-icon
            >
              <h2>Please connect your wallet to use Norte</h2>
            </el-alert>
          </div>
        </div>
        <div v-if="addressStateRef && !connectedToDesiredNetwork">
          <div class="connect-wallet-alert-div">
            <el-alert
              class="connect-wallet-alert"
              :closable="false"
              effect="dark"
              type="warning"
              show-icon
            >
              <h2>
                Please connect your wallet to the
                {{ desiredNetworkName }} network.
              </h2>
            </el-alert>
          </div>
        </div>
        <el-row v-if="connectedToDesiredNetwork">
          <el-col
            :xs="24"
            :sm="10"
            :md="13"
            :lg="14"
            :xl="15"
            class="left-container"
          >
            <div class="grid-content bg-purple"></div>
            <Player />
          </el-col>
          <el-col
            :xs="24"
            :sm="14"
            :md="11"
            :lg="10"
            :xl="9"
            class="right-container"
          >
            <div class="grid-content bg-purple-light"></div>
            <ToolPanel />
          </el-col>
        </el-row>
      </div>
      <hr class="golden-divider" />
      <h2 class="norte-title">Example Mints</h2>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card
            style="margin: 5% 10% 5% 0%; width: 90% !important"
            :body-style="{ padding: '10px' }"
          >
            <el-image
              :src="require('../assets/images/norte-classic-prime-preview.png')"
              class="preview-image"
              fit="contain"
              style="width: 100%; height: auto; aspect-ratio: 1"
            >
              <template #error>
                <div
                  style="
                    width: 100%;
                    height: 0;
                    padding-top: 40%;
                    padding-bottom: 60%;
                    font-size: 20px;
                  "
                >
                  <el-icon :size="50" style="width: 3em; height: 3em">
                    <full-screen style="width: 5em; height: 5em" />
                  </el-icon>
                  <br />Loading...
                </div>
              </template>
            </el-image>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card
            style="margin: 5% 10% 5% 0%; width: 90% !important"
            :body-style="{ padding: '10px' }"
          >
            <el-image
              :src="require('../assets/images/norte-light-preview.png')"
              class="preview-image"
              fit="contain"
              style="width: 100%; height: auto; aspect-ratio: 1"
            >
              <template #error>
                <div
                  style="
                    width: 100%;
                    height: 0;
                    padding-top: 40%;
                    padding-bottom: 60%;
                    font-size: 20px;
                  "
                >
                  <el-icon :size="50" style="width: 3em; height: 3em">
                    <full-screen style="width: 5em; height: 5em" />
                  </el-icon>
                  <br />Loading...
                </div>
              </template>
            </el-image>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card
            style="margin: 5% 10% 5% 0%; width: 90% !important"
            :body-style="{ padding: '10px' }"
          >
            <el-image
              :src="
                require('../assets/images/norte-classic-particles-preview.png')
              "
              class="preview-image"
              fit="contain"
              style="width: 100%; height: auto; aspect-ratio: 1"
            >
              <template #error>
                <div
                  style="
                    width: 100%;
                    height: 0;
                    padding-top: 40%;
                    padding-bottom: 60%;
                    font-size: 20px;
                  "
                >
                  <el-icon :size="50" style="width: 3em; height: 3em">
                    <full-screen style="width: 5em; height: 5em" />
                  </el-icon>
                  <br />Loading...
                </div>
              </template>
            </el-image>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card
            style="margin: 5% 10% 5% 0%; width: 90% !important"
            :body-style="{ padding: '10px' }"
          >
            <el-image
              :src="require('../assets/images/norte-cipher-preview.png')"
              class="preview-image"
              fit="contain"
              style="width: 100%; height: auto; aspect-ratio: 1"
            >
              <template #error>
                <div
                  style="
                    width: 100%;
                    height: 0;
                    padding-top: 40%;
                    padding-bottom: 60%;
                    font-size: 20px;
                  "
                >
                  <el-icon :size="50" style="width: 3em; height: 3em">
                    <full-screen style="width: 5em; height: 5em" />
                  </el-icon>
                  <br />Loading...
                </div>
              </template>
            </el-image>
          </el-card>
        </el-col>
      </el-row>
      <hr class="golden-divider" />
      <h2 class="norte-title">Exclusive Experiences & Rewards</h2>
      <el-row class="s1-section-4">
        <p>
          Norte rewards CryptoArte early collectors, top collectors, prime
          painting holders, and all CryptoArte holders through the whitelist
          tiers below while still giving everyone else plenty of opportunity to
          participate.
        </p>
      </el-row>
      <div>
        <el-table :data="tableData">
          <el-table-column prop="tier" label="Tier" min-width="200" />
          <el-table-column prop="access" label="Minting Access" />
          <el-table-column prop="experience" label="Minting Experiences" />
          <el-table-column prop="rewards" label="Rewards & Pricing" />
        </el-table>
      </div>
      <el-row class="s1-section-4">
        <p>
          You can qualify for multiple tiers and earn access to their combined
          experiences. You can only mint a given style once per address, but
          certain updates will be possible for owners after launch. Public
          supply limited to 5,000 total. Whitelisted supply is only limited by
          style access based on tiers.
        </p>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { computed, ref, onBeforeMount, watch } from 'vue'
  import { useStore } from 'vuex'
  import ToolPanel from '@/components/projects/norte/norte-mint/tool-panel'
  import Player from '@/components/projects/norte/norte-mint/player'
  import { selectProjectContract } from '@/services/ProjectService'
  import { NETWORK_IDS, NETWORK_NAMES } from '@/constants/walletConstants'

  export default {
    name: 'Norte',

    components: {
      ToolPanel,
      Player,
    },

    setup() {
      // Hooks
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user },
      } = store

      // Read from state
      const addressState = computed(() => user.walletAddress)
      const web3 = computed(() => contractState.web3)

      const addressStateRef = ref(addressState)
      const networkId = computed(() => user.networkId)
      const connectedToDesiredNetwork = ref(false)
      const desiredNetworkName = ref('')

      onBeforeMount(async () => {
        dispatch('setEditMode', false)
        //reset iteration for new mints
        dispatch('setIteration', 1)

        if (web3.value) {
          await selectProjectContract('norte', web3.value, dispatch)
          connectedToDesiredNetwork.value =
            networkId.value &&
            NETWORK_IDS[process.env.NODE_ENV] == networkId.value
        }

        desiredNetworkName.value =
          NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
      })

      watch([web3, networkId], async () => {
        await selectProjectContract('norte', web3.value, dispatch)
        connectedToDesiredNetwork.value =
          networkId.value &&
          NETWORK_IDS[process.env.NODE_ENV] == networkId.value

        desiredNetworkName.value =
          NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
      })

      return {
        addressStateRef,
        connectedToDesiredNetwork,
        desiredNetworkName,
      }
    },
    data() {
      return {
        tableData: [
          {
            tier: '1 - CryptoArte Early Collectors & Top Collectors (>= 20), team members, guest artists',
            access: 'Early access',
            experience: 'All holder styles + 4 exclusive styles',
            rewards: '1 free mint, 0.05 eth per mint thereafter (33% off)',
          },
          {
            tier: '2 - CryptoArte Prime Painting Holders & Top Collectors (>=10), team members, guest artists, special invites',
            access: 'Early access',
            experience: 'All holder styles + 4 exclusive styles',
            rewards: '1 free mint, 0.05 eth per mint thereafter (33% off)',
          },
          {
            tier: '3 - CryptoArte Holders, team members, guest artists, special invites',
            access: 'Early access',
            experience: 'Public styles + 9 additional styles',
            rewards: '0.05 eth per mint (33% off)',
          },
          {
            tier: '4 - Public',
            access: 'Public access',
            experience: '9 base styles',
            rewards: '0.075 eth per mint',
          },
        ],
      }
    },
    methods: {
      tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row'
        } else if (rowIndex === 3) {
          return 'success-row'
        }
        return ''
      },
      tableCellClassName() {
        return 's1-table-cell-class'
      },
    },
  }
</script>

<style scoped>
  .norte {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .main-container {
    margin: 0 10%;
  }

  .left-container,
  .right-container {
    padding: 2px;
    width: 100%;
    height: 100%;
  }
  .description {
    margin: 1em 0;
    line-height: 1.5em;
    padding: 0 10px 10px 10px;
  }
  .description p {
    text-align: left;
  }
  .s1-section-4 {
    text-align: left;
    line-height: 1.5em;
  }
  .whitelist-tiers-table {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .preview-image {
    width: 95%;
    height: 95%;
  }
  .norte-title {
    text-align: left;
    margin-top: 50px;
  }
  .golden-divider {
    width: 100%;
    margin: 3% 0% 3% 0%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #bf953f,
      #fcf6ba,
      #b38728,
      #fbf5b7,
      #aa771c
    );
  }
</style>
