export const DEFAULT_VARS = {
  1: 'Size',
  2: 'Density',
}

export const MODES = {
  1: {
    name: 'Classic',
    styles: {
      1: { name: 'Rectangle' },
      2: { name: 'Circle' },
      3: { name: 'Particles' },
      4: { name: 'Triangle' },
      5: { name: 'Circles' },
      6: {
        name: 'Prime',
        vars: {
          1: 'Magic',
          2: 'Density',
        },
      },
    },
  },
  2: {
    name: 'Polar',
    styles: {
      1: { name: 'Rectangle' },
      2: { name: 'Circle' },
      3: {
        name: 'Particles',
        vars: {
          1: 'Particles',
          2: 'Density',
        },
      },
      4: { name: 'Triangle' },
      5: { name: 'Circles' },
      6: {
        name: 'Prime',
        vars: {
          1: 'Magic',
          2: 'Density',
        },
      },
    },
  },
  3: {
    name: 'Pulsar',
    styles: {
      1: { name: 'Star' },
      2: { name: 'Light' },
      3: { name: 'Planes' },
      4: { name: 'Ship' },
      5: {
        name: 'OjO',
        vars: {
          1: 'Steps',
          2: 'Layers Opacity',
        },
      },
      6: { name: 'Mariposa' },
    },
    vars: {
      1: 'Steps',
      2: 'Opacity',
    },
  },
  4: {
    name: 'Carte',
    styles: {
      1: { name: 'Cuatro' },
      2: { name: 'Cuarenta' },
      3: { name: 'Robot' },
      4: {
        name: 'Disco',
        vars: {
          1: 'Horizontal Opacity',
          2: 'Vertical Opacity',
        },
      },
      5: { name: 'Cipher' },
      6: {
        name: 'Uno',
        vars: {
          1: 'Friends & Golden border',
          2: 'Opacity',
        },
      },
    },
    vars: {
      1: 'Golden border',
      2: 'Opacity',
    },
  },
}
